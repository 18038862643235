import React, { useEffect, useState } from 'react'
import {  Container, Row, Table } from 'react-bootstrap'
import Header from '../../Components/Header'
import {  Navigate } from 'react-router-dom';
import moment from 'moment';
import { FaSort } from 'react-icons/fa';
const Invoice = () => {
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortOrderWtgNo, setSortOrderWtgNo] = useState('asc');
  const [loading, setLoading] = useState(true);
  const [dgrlist, setDgrlist] = useState([]);
  console.log("dgrlist", dgrlist)
  const fetchData = async () => {
    try {
      const customerId = localStorage.getItem("customerId");
      const response = await fetch("https://api.srivarugreenenergy.com/customerapi/daily_generations/list.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: customerId
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      setLoading(false);
      if (responseData.status === 200) {
        const reversedData = responseData.data.daily_generation.reverse();
        setDgrlist(reversedData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleSort = () => {
    const sortedData = [...dgrlist].sort((a, b) => {
      const dateA = moment(a.dg_date, 'YYYY-MM-DD');
      const dateB = moment(b.dg_date, 'YYYY-MM-DD');

      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setDgrlist(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sorting order
  };
  const handleSortByWtgNo = () => {
    const sortedData = [...dgrlist].sort((a, b) => {
      const extractParts = (wtgNo) => {
        const wtgNoStr = String(wtgNo); // Ensure wtgNo is treated as a string
        const numericPart = parseInt(wtgNoStr.match(/\d+/), 10) || 0; // Extract the first number or default to 0
        const alphaPart = wtgNoStr.replace(numericPart, ''); // Get the rest of the string
        return { numericPart, alphaPart };
      };

      const { numericPart: numericPartA, alphaPart: alphaPartA } = extractParts(a.wtg_no);
      const { numericPart: numericPartB, alphaPart: alphaPartB } = extractParts(b.wtg_no);

      // Primary sorting by numeric part
      if (numericPartA !== numericPartB) {
        return sortOrderWtgNo === 'asc' ? numericPartA - numericPartB : numericPartB - numericPartA;
      }
      // Secondary sorting by alphanumeric part
      else {
        return sortOrderWtgNo === 'asc' ? alphaPartA.localeCompare(alphaPartB) : alphaPartB.localeCompare(alphaPartA);
      }
    });

    setDgrlist(sortedData);
    setSortOrderWtgNo(prevSortOrder => (prevSortOrder === 'asc' ? 'desc' : 'asc')); // Toggle sorting order
  };

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <div id='main'>
        <Header />
        <Container fluid className='main my-3 '>
          <Row>
            <div style={{ overflow: "auto", maxHeight: "740px", maxWidth: "1880px" }} className='mt-2'>
              <Table striped bordered hover  >
                <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                  <tr>

                    <th >S.No</th>
                    <th onClick={handleSort} style={{ cursor: 'pointer' }}>
                      Date <FaSort /> {/* Filter Icon */}
                    </th>
                    <th onClick={handleSortByWtgNo} style={{ cursor: 'pointer' }}>
                      WEG No. <FaSort /> {/* Filter Icon */}
                    </th>
                    <th >Loc. No</th>
                    <th >Total Production</th>
                    <th >Gen Hrs</th>
                    <th >KWH Import EB</th>
                    <th >KWH Export EB</th>
                    <th >Kvarh Imp EB</th>
                    <th >Kvarh exp EB</th>
                    <th >M/c Fault hrs</th>
                    <th >M/c Maintenance hrs</th>
                    <th >Grid fault hrs</th>
                    <th >Grid down hrs</th>
                    <th >Lull hrs</th>
                    <th >M/c availability</th>
                    <th >Grid availability</th>
                    <th >Kvarh Imp %</th>
                  </tr>
                </thead>
                <tbody>
                  {dgrlist.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{moment(data.dg_date).format('DD-MM-YYYY')}</td>
                      <td>{data.wtg_no}</td>
                      <td>{data.location_no}</td>
                      <td>{data.total_production}</td>
                      <td>{data.gen_hourtotal}</td>
                      <td>{data.kwh_imp || 0}</td>
                      <td>{data.kwh_exp || 0}</td>
                      <td>{data.kvarh_imp || 0}</td>
                      <td>{data.kvarh_exp || 0}</td>
                      <td>{data.error_overtotal || "00:00"}</td>
                      <td>{data.maintenance_overtotal || "00:00"}</td>
                      <td>{data.gridfault_overtotal || "00:00"}</td>
                      <td>{data.griddrop_overtotal || "00:00"}</td>
                      <td>{data.lull_hrs || "00:00"}</td>
                      <td>{data.machine_availability || 0}</td>
                      <td>{data.grid_availability || 0}</td>
                      <td>{data.kvarh_imp_per || 0}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Invoice
// src/LineChart.js
import React, { useState, useEffect } from 'react';
import { Col, Container, Row,  Table,  } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../../Components/Header';
import {Navigate } from 'react-router-dom';
import moment from 'moment';


const CutomerTurbine = () => {
    const [loading, setLoading] = useState(true);
    const [turbine, setTurbine] = useState([]);
    console.log("turbine", turbine)
    const fetchData = async () => {
        try {
            const customerId = localStorage.getItem("customerId");
            const response = await fetch("https://api.srivarugreenenergy.com/customerapi/customerturbine/list.php",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        user_id: customerId
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const responseData = await response.json();
            setLoading(false);
            if (responseData.status === 200) {
                setTurbine(responseData.data);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };
    useEffect(() => {
        fetchData(); // Call fetchData directly in useEffect
    }, []);



    const [isLoggedIn, setIsLoggedIn] = useState(true);
    useEffect(() => {
        const checkSession = async () => {
            try {
                // Check session logic here, e.g., fetch from local storage or server
                const session = localStorage.getItem('session');
                if (!session) {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Error checking session:', error.message);
                setIsLoggedIn(false);
            }
        };
        checkSession();
    }, []);
    if (!isLoggedIn) {

        return <Navigate to="/login" replace />;
    }

    return (
        <>


            <div id="main">
                <Header />
                <div className="bg-white my-3 rounded">
                    <Container fluid>
                        <Row>

                        </Row>
                    </Container>

                    <div className='m-3'>
                        <Row>
                            <Col lg={12} md={12} xs={12} className='mb-5'>
                                <div className='h5 text-center mb-2'></div>
                                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                                    <Table striped bordered hover  >
                                        <thead>
                                            <tr>
                                                <th >S.No</th>
                                                <th >WEG No.</th>
                                                <th >Customer Name</th>
                                                <th >Loc. No</th>
                                                <th >HTSC NO</th>
                                                <th >DOC</th>
                                                <th >Site</th>
                                                <th >Place</th>
                                                <th >Model</th>
                                                <th >Capacity</th>
                                                <th >Tower Ht</th>
                                                <th >Contract type</th>
                                                <th >Feeder Voltage</th>
                                                <th >Feeder Name</th>
                                                <th >Substation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan="15" className="text-center">Loading...</td>
                                                </tr>
                                            ) : (
                                                turbine.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.wtg_no}</td>
                                                        <td>{item.customer_name}</td>
                                                        <td>{item.loc_no}</td>
                                                        <td>{item.htsc_no}</td>
                                                        <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                                                        <td>{item.site_name}</td>
                                                        <td>{item.location_name}</td>
                                                        <td>{item.model_type}</td>
                                                        <td>{item.capacity}</td>
                                                        <td>{item.tower_ht}</td>
                                                        <td>{item.contract_name}</td>
                                                        <td>{item.feeder_voltage}</td>
                                                        <td>{item.feed_name}</td>
                                                        <td>{item.sub_station}</td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>

    );
};

export default CutomerTurbine;

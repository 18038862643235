import React from 'react';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { MdOutlinePerson, MdOutlineDashboard } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import '../Components/Header.css';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [customerName, setCustomerName] = React.useState("");

  React.useEffect(() => {
    const session = localStorage.getItem("session");
    const storedCustomerName = localStorage.getItem("customerName");
    if (session) {
      setLoggedIn(true);
      setCustomerName(storedCustomerName);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("session");
    localStorage.removeItem("customerName");
    window.location.replace("/login");
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </span>
  ));

  return (
    <Navbar className='navfix navbg header fixed-top' expand="lg">
      <Container fluid className='px-5'>
        <div className='user-logo'>
          <img src={require('../assest/images/srivarulogo.png')} className='img-fluid logo' alt="Logo" />
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <NavLink to="/console/dashboard" className="nav-link">
              <span className="list-icon"><MdOutlineDashboard /></span>
              <span className="list-text">Dashboard</span>
            </NavLink>
            <NavLink to="/console/dailygen" className="nav-link">
              <span className="list-icon"><MdOutlineDashboard /></span>
              <span className="list-text">Daily Generation</span>
            </NavLink>
            <NavLink to="/console/dgr" className="nav-link">
              <span className="list-icon"><MdOutlineDashboard /></span>
              <span className="list-text">Dgr Report</span>
            </NavLink>
            <NavLink to="/console/turbine" className="nav-link">
              <span className="list-icon"><MdOutlineDashboard /></span>
              <span className="list-text">Turbine</span>
            </NavLink>
          </Nav>

        </Navbar.Collapse>
        <div className='ms-auto user-id'>
          {loggedIn && (
            <div className='d-flex align-items-center'>
              <span className="customer-name mt-2">{customerName}</span>
              <span className='ms-2'>
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <MdOutlinePerson style={{ cursor: 'pointer', fontSize: '24px' }} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='drop-lefts'>
                    <Dropdown.ItemText>
                      <strong></strong>
                    </Dropdown.ItemText>
                    <Dropdown.Item onClick={handleLogout} className="d-flex align-items-center">
                      <FaLock className="me-2" />
                      LogOut
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </div>
          )}
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;

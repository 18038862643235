import React, { useState, useEffect } from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Pages/main/Main.css'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Header from './Components/Header';
import DashBoard from './Pages/dashboard/DashBoard';
import Dailygen from './Pages/dailygeneration/Dailygen'
import DGR from './Pages/dgr/DGR';
import CustomerTurbine from "./Pages/turbine/CustomerTurbine";
function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem("session");
    if (session) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const handleLogin = () => {
    localStorage.setItem("session", "true");
    setLoggedIn(true);
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={loggedIn ? (<Navigate to="/console/dashboard" replace />) : (<Navigate to="/login" replace />)}></Route>
          <Route path='/login' element={<Login onLogin={handleLogin} />}></Route>
        </Routes>

        <Routes>
          <Route path='/console/dashboard' element={<DashBoard />}></Route>
          <Route path='/console/dailygen' element={<Dailygen />}></Route>
          <Route path='/console/dgr' element={<DGR />}></Route>
          <Route path='/console/turbine' element={<CustomerTurbine />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
